<template>
    <div class="technician-detail-table">
        <ul class="table-header">
            <li v-for="item in tableData.headList"
                :key="item.filed"
                :style="{minWidth:item.width}">
                {{item.name}}
            </li>
        </ul>
        <div class="table-body"
             v-if="!!tableData&&tableData.dataList.length>0">
            <ul class="row"
                v-for="(data,index) in tableData.dataList"
                :key="index">
                <li v-for="col in tableData.headList"
                    :class="data[col.filed]<0?'warning':''"
                    :style="{minWidth:col.width}">
                    {{formatData(data,col)}}
                </li>
            </ul>
        </div>
        <p class="no-data" v-else>暂无数据</p>
    </div>
</template>

<script>
	export default {
		name:"technician-detail",
		props:{
			tableData:Object,
		},
		computed:{
			formatData(){
				return function(data,col){
					if(col.filed == 'remain_time'){
						return data[col.filed]?data[col.filed]:"0"
					}else{
						return data[col.filed]?data[col.filed]:'-'
                    }
					
				}
			},
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .table-header{
        background:#E6F2FE;
        @extend %flex-center-row;
        color:#909699;
        font-size:22px;
        line-height:62px;
        height:62px;
        border-bottom:1px solid $border-color;

        li{
            flex:1;
            text-align:center;
            white-space:nowrap;
        }
    }

    .table-body{
        max-height:372px;
        overflow:auto;
        -webkit-overflow-scrolling:touch;
        overflow-scrolling:touch;
        .row{
            @extend %flex-center-row;
            color:#666666;
            font-size:22px;
            line-height:62px;
            height:62px;
            border-bottom:1px solid $border-color;
            border-right:1px solid $border-color;
            li{
                flex:1;
                text-align:center;
                white-space:nowrap;
                overflow:hidden;
                border-left:1px solid $border-color;
            }
        }
        .warning{
            color:#E13744
        }
    }
    
    .no-data{
        line-height:62px;
        text-align:center;
        color:#666666;
        border-bottom:1px solid $border-color;
        border-right:1px solid $border-color;
        border-left:1px solid $border-color;
    }
</style>